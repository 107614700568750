import React, { Component } from 'react'
import Card from './card.js'
const NoData = Loader.loadBaseComponent('NoData');
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", 'InfiniteScrollLayout');

class Content extends Component {
  loadMore = () => {
    let { searchData, onChange, list, reqData } = this.props;
    if(searchData.limit > reqData.length) {
      return;
    }
    searchData.minId = list[list.length - 1].imgId;
		onChange && onChange(searchData);
  }

  renderItem = (item, index) => {
    const rects = this.getRects(item)
    return (
      <div key={index}>
        <Card item={item} rects={rects}/>
      </div>
    )
  }

  handleRects = (type, rects,item) => {
    let rectsType = type + 'Rects'
    if(item[rectsType].length > 0){
      item[rectsType].forEach(v => {
        rects.push({
          type: type,
          value: v.rect,
          feature: v.feature,
        })
      })
    }
  }

  getRects = item => {
    let rects = []
    this.handleRects('face', rects, item)
    this.handleRects('body', rects, item)
    this.handleRects('vehicle', rects, item)
    this.handleRects('nonVehicle', rects, item)
    return rects
  }

  render(){
    const { list, total, loading, listKey } = this.props;
    return (
      <div className='video-abstract-container'>
        {list.length > 0 
          ? <InfiniteScrollLayout 
              key={listKey}
              count={total}
              itemWidth={420}
              itemHeight={312}
              pdWidth={60}
              data={list}
              hasBackTop={true}
              loadMore={this.loadMore}
              hasLoadMore={!loading}
              renderItem={this.renderItem}
            /> 
          : <NoData imgType={2} title={'数据'}/>}
      </div>
    )
  }
}
export default Content