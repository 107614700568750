import React, { Component } from "react";
import { Tooltip, message } from "antd";
import moment from "moment";

const IconFont = Loader.loadBaseComponent("IconFont");
const PictureView = Loader.loadBusinessComponent("PictureView");
const Rect_Type = {
  face: "faceLibrary",
  body: "bodyLibrary",
  vehicle: "vehicleLibrary",
  nonVehicle: "nonVehicleLibrary"
};
class Card extends Component {
  constructor(props) {
    super(props);
    this.pictureCanvas = React.createRef();
    this.state = {
      rectSearchStatus: false,
      imgError: false,
      collectStatus: false
    };
  }

  componentWillMount() {
    const { item } = this.props;
    const collectStatus = !!BaseStore.mediaLib.hasCollectedKeyOfImage(item.imgId);
    if (collectStatus) {
      this.setState({
        collectStatus
      });
    }
    SocketEmitter.on(SocketEmitter.eventName.updataCollectedState, this.updateCollectStatus);
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.updataCollectedState, this.updateCollectStatus);
  }

  updateCollectStatus = selectList => {
    const { item } = this.props;
    const temp = selectList.find(v => v.pictureId === item.imgId);
    if (temp) {
      this.setState({
        collectStatus: false
      });
    }
  };

  handleRectSearch = () => {
    const { rectSearchStatus } = this.state;
    if (rectSearchStatus) {
      this.pictureCanvas.current.changeSelectStatus(false);
    } else {
      this.pictureCanvas.current.changeSelectStatus(true);
    }
    this.setState({
      rectSearchStatus: !rectSearchStatus
    });
  };

  cancelCollection = () => {
    const { item } = this.props;
    // 根据pictureId反查id
    let collectItem = BaseStore.mediaLib.mediaImageListLevel.find(v => item.imgId === v.pictureId);
    BaseStore.mediaLib.deleteFavorite(collectItem);
  };

  addCollection = e => {
    const { item } = this.props;
    const options = {
      start: { clientX: e.clientX, clientY: e.clientY },
      url: item.sceneUrl
    };
    Utils.animateFly(options);
    let collectDeviceId = item.deviceId;
    if (!collectDeviceId) {
      let cameraInfo = BaseStore.device.deviceArray.find(v => {
        return v.cid === item.cid;
      });
      collectDeviceId = cameraInfo.id;
    }
    BaseStore.mediaLib
      .addPictureFavorite({
        pictureId: item.imgId,
        deviceId: collectDeviceId,
        deviceName: item.deviceName,
        captureTime: +item.captureTime,
        scenceImageUrl: item.sceneUrl,
        smallImageUrl: item.sceneUrl,
        pictureType: "1",
        source: 2
      })
      .then(() => {
        this.setState({
          collectStatus: true
        });
      })
      .catch(err => {
        message.error("收藏失败");
      });
  };

  handleCollectiton = e => {
    const { collectStatus } = this.state;
    if (collectStatus) {
      this.cancelCollection();
    } else {
      this.addCollection(e);
    }
  };

  catchError = () => {
    this.setState({
      imgError: true
    });
  };

  // 框选搜图事件
  goPage = ({ name, imageUrl, searchType = "1", feature = "", defaultRect, isSelect }) => {
    const { item, rects } = this.props;
    const captureTime = +(item.captureTime || new Date());
    let id = Utils.uuid();
    let url = imageUrl;
    let frameUrl = item.sceneUrl;
    const searchData = {
      startTime: moment(captureTime).subtract(72, "h") * 1,
      endTime: moment(captureTime).add(72, "h") * 1,
      timerTabsActive: 2
    };    
    LM_DB.add("parameter", {
      id,
      url,
      frameUrl: isSelect ? url : frameUrl,
      imgId: isSelect ? null : item.imgId,
      searchData,
      rects: isSelect ? null : rects,
      feature: isSelect ? null : feature,
      defaultRect
    }).then(() => {
      BaseStore.tab.goPage({ moduleName: name, data: { id, isSearch: true, searchType } });
    });
  };

  /**
   * @desc 结构化跳转
   */
  onClickDefaultRect = (item, base64) => {
    if (!item.feature) {
      message.info("没有提取到结构化特征");
      return;
    }
    this.goPage({
      name: Rect_Type[item.type],
      imageUrl: base64,
      searchType: "2",
      feature: item.feature,
      defaultRect: item
    });
  };

  render() {
    const { item, rects } = this.props;
    const { rectSearchStatus, imgError, collectStatus } = this.state;
    return (
      <div className={`video-item ${imgError ? "error" : "success"}`}>
        <div className="content">
          <PictureView
            ref={this.pictureCanvas}
            imagePath={item.sceneUrl}
            width={"400"}
            goPage={this.goPage}
            rectMenuVisible={true}
            rects={rects}
            showDefaultRect={false}
            showRectInfo={false}
            onClickDefaultRect={this.onClickDefaultRect}
          />
        </div>
        <div className="footer">
          <div className="item">
            <IconFont type={"icon-S_Bar_Add"} theme="outlined" />
            {item.deviceName}
          </div>
          <div className="item">
            <IconFont type={"icon-S_Edit_ClockEnd"} theme="outlined" />
            {moment(item.captureTime * 1).format(Shared.format.dataTime)}
          </div>
          <div className="btn-group">
            <Tooltip title={`${collectStatus ? "取消" : ""}收藏`}>
              <IconFont
                className={`${collectStatus ? "active" : ""}`}
                type="icon-S_Photo_Keep"
                onClick={this.handleCollectiton}
              />
            </Tooltip>
            <Tooltip title={!rectSearchStatus ? "框选搜图" : "取消框选"}>
              <IconFont
                type="icon-S_View_SearchBox"
                onClick={() => this.handleRectSearch()}
                className={rectSearchStatus ? "active" : ""}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
